import { FC, useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

interface CountUpProps {
	start: number;
	end: number;
	duration: number;
}

const NumberCounter: FC<CountUpProps> = ({ start, ...restProps }) => {
	const [viewPortEntered, setViewPortEntered] = useState(false);

	return (
		<>
			<CountUp start={viewPortEntered ? null! : 0} {...restProps}>
				{({ countUpRef }) => (
					<VisibilitySensor
						active={!viewPortEntered}
						onChange={(isVisible: any) => {
							if (isVisible) {
								setViewPortEntered(true);
							}
						}}
						delayedCall
					>
						<span ref={countUpRef} />
					</VisibilitySensor>
				)}
			</CountUp>
		</>
	);
};

export default NumberCounter;
