import React, { FC } from "react";
import NumberCounter from "./NumberCounter";

const Counter: FC = () => {
	return (
		<div className="counter-block">
			<div className="container">
				<div className="row counter-items g-xl-0" id="unique-id">
					<div id="myTargetElement" className="item col-lg-3 col-md-6">
						<div className="text-holder">
							<div className="lead-text">
								$
								<NumberCounter start={0} end={500} duration={2.75} />
								Million
								<span className="plus-text">+</span>
								<p className="text">RETAIL SOLD</p>
							</div>
						</div>
					</div>

					<div id="myTargetElement2" className="item col-lg-3 col-md-6">
						<div className="text-holder">
							<div className="lead-text">
								<NumberCounter start={0} end={45} duration={2.75} />
								<span>K</span>
								<p className="text">HAPPY CUSTOMERS</p>
							</div>
						</div>
					</div>

					<div id="myTargetElement3" className="item col-lg-3 col-md-6">
						<div className="text-holder">
							<div className="lead-text">
								<NumberCounter start={0} end={10000} duration={2.75} />
								<span className="plus-text">+</span>
								<p className="text">POSITIVE REVIEWS</p>
							</div>
						</div>
					</div>

					<div id="myTargetElement4" className="item col-lg-3 col-md-6">
						<div className="text-holder">
							<div className="lead-text">
								<NumberCounter start={0} end={100} duration={2.75} />
								<span>%</span>
								<p className="text">SATISFACTION</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Counter;
